.loader {
    position: fixed;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 200px;
    }
}