$mobile: "only screen and (max-width: 767px)";
.customModal {
    .react-responsive-modal-closeButton {
        display: none;
    }
    button:focus {
        outline: none;
    }
    h5 {
        margin: 0;
        font-weight: 900;
        font-size: 16px;
    }
    p {
        font-size: 14px;
        font-weight: 300;
        padding-bottom: 15px;
    }
    .modal-btns {
        display: flex;
        justify-content: flex-end;
        .confirm-btn {
            border: none;
            outline: none;
            background: rgba(0,0,0,.7);
            color: #fff;
            padding: 7px 15px;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
                background: rgba(0,0,0,1);
            }
        }
        .cancel-btn {
            border: 1px solid #000;
            margin-right: 10px;
            outline: none;
            background: transparent;
            border-radius: 3px;
            padding: 7px 15px;
            cursor: pointer;
        }
    }
}
.customOverlay {
    background: rgba(0,0,0,.2);
}
@media #{$mobile} {
    .delete-btn {
        position: absolute;
        top: 8px;
        right: 7px;
    }
}