$mobile: "only screen and (max-width: 767px)";
.details-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    @media #{$mobile} {
        display: block;
    }
    h4 {
        margin: 0;
        font-size: 18px;
        margin-bottom: 23px;
        margin-top: 3px;
        @media #{$mobile} {
            margin-bottom: 10px;
        }
    }
    h5 {
        margin: 10px 0px 10px 0px;
        font-size: 15px;
    }
    p{
        padding-bottom: 7px;
        margin: 0;
        font-weight: 300;
        font-size: 14px;
        span {
            font-weight: 400;
        }
    }
    .order-details-btns {
        padding: 0px 0px 20px 20px;
        @media #{$mobile} {
            display: none;
        }
        button {
            margin-left: 8px;
            border: none;
            outline: none;
            padding: 3px 10px;
            background-color: transparent;
            color: deepskyblue;
            border: 1px solid deepskyblue;
            border-radius: 3px;
        }
        .new {
            color: rgb(37, 155, 37);
            border: 1px solid rgb(37, 155, 37);
        }
        .completed {
            color: red;
            border: 1px solid red;
        }
    }
    .order-details-mobile {
        display: none;
        @media #{$mobile} {
            display: block;
            text-align: right;
            padding-bottom: 5px;
        }
    }
}
.order-detail-modal {
    width: 75%;
    @media #{$mobile} {
        width: 90%;
        max-height: 85vh;
        overflow-y: auto;
    }
    .react-responsive-modal-closeButton {
        display: none;
    }
    .item-item {
        font-weight: 100;
        font-size: 14px;
        display: flex;
        min-height: 50px;
        align-items: center;
        margin-bottom: 5px;
        border-bottom: 1px solid lightgray;
        div[class^="ord-col-"] {
          width: 30%;
          padding-left: 10px;
        }
    }
    .title-item {
        background-color: lightgray;
        color: #000000;
        font-weight: 500;
        border-bottom: none;
    }
    .item-name {
        font-weight: 400;
    }
    .delete-all-btn {
        margin-top: 20px;
        border: none;
        outline: none;
        padding: 7px 15px;
        background: #000;
        color: #fff;
        cursor: pointer;
    }
    .customer-info {
        padding-bottom: 10px;
        h4 {
            margin-bottom: 10px;
        }
        p {
            margin: 5px 0px;
        }
    }
    .footer-btns {
        padding-top: 15px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid lightgray;
        button {
            margin-left: 8px;
            border: none;
            outline: none;
            padding: 8px 15px;
            background: #dc7100;
            color: #fff;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
                background: #a05302;
            }
        }
        .cancel-btn {
            background: transparent;
            border: 1px solid #000000;
            color: #000;
            &:hover {
                background: transparent;
            }
        }
        .open-btn {
            background: rgba(0, 155, 0, .9);
            &:hover {
                background: rgba(0, 155, 0, 1);
            }
        }
    }
}
.order-details-modal-overlay {
    background: rgba(0,0,0,.2);
}
.detail-top-right {
    p {
        display: flex;
        justify-content: flex-end;
        @media #{$mobile} {
            justify-content: flex-start;
        }
    }
}