$mobile: "only screen and (max-width: 767px)";
.checkout-modal {
    width: 75%;
    @media #{$mobile} {
        width: 90%;
    }
    .react-responsive-modal-closeButton {
        display: none;
    }
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p, .input-c {
            width: 50%;
        }
        input {
            border: none;
            outline: none;
            border: 1px solid #cccccc;
            border-radius: 5px;
            padding: 0;
            margin: 0;
            height: 100%;
            width: 100%;
            padding-left: 5px;
            font-weight: 100;
            padding: 8px 10px 8px 10px;
        }
        .input-c {
            display: flex;
            position: relative;
            span {
                position: absolute;
                top: 11px;
            }
            .right-icon {
                padding: 8px 15px 8px 10px;
            }
            .right-icon-span {
                right: 5px;
            }
            .left-icon {
                padding: 8px 10px 8px 15px;
            }
            .left-icon-span {
                left: 5px;
            }
        }
    }
    .footer-btns {
        padding-top: 15px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid lightgray;
        button {
            margin-left: 8px;
            border: none;
            outline: none;
            padding: 8px 15px;
            background: #dc7100;
            color: #fff;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
                background: #a05302;
            }
        }
        .black-btn {
            background: rgba($color: #000000, $alpha: .7);
            &:hover {
                background: rgba($color: #000000, $alpha: 1);
            }
        }
        .cancel-btn {
            background: transparent;
            border: 1px solid #000000;
            color: #000;
            &:hover {
                background-color: transparent;
            }
        }
    }
}
.customOverlay {
    background: rgba(0,0,0,.2);
}