$mobile: "only screen and (max-width: 767px)";
.login-page {
    justify-content: center;
    height: calc(100% - 50px);
    background-color: #efefefef;
    padding: 25px;
    display: flex;
    align-items: center;
    @media #{$mobile} {
       padding: 15px;
       height: calc(100% - 30px);
    }
    .form-main-container {
        display: flex;
        height: 100%;
        width: 100%;
        max-width: 700px;
        max-height: 400px;
        .logo-section, .form-section {
            display: flex;
            width: 50%;
            align-items: center;
            @media #{$mobile} {
                width: 100%;
            }
        }
        .logo-section {
            background-color: darkslategray;
            padding-right: 25px;
            border-radius: 15px 0px 0px 15px;
            justify-content: flex-end;
            background-image: url("/images/login_bg.jpg");
            background-size: cover;
            background-position: 100%;
            position: relative;
            border: 1px solid lightgray;
            border-right: none;
            @media #{$mobile} {
                display: none;
            }
            .logo-container {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: flex-end;
                padding-right: 25px;
                background: rgba(0,0,0,.7);
                border-radius: 15px 0px 0px 15px;
            }
        }
        .form-section {
            background-color: #fff;
            padding-left: 25px;
            border-radius: 0px 15px 15px 0px;
            border: 1px solid lightgray;
            border-left: none;
            .mobile-logo {
                display: none;
            }
            @media #{$mobile} {
                border-radius: 15px 15px 15px 15px;
                border-left: 1px solid lightgray;
                padding-left: 0px;
                justify-content: center;
                text-align: center;
                .mobile-logo {
                    display: block;
                    padding-bottom: 15px;
                }
                form {
                    width: 100%;
                    input {
                        width: 75%;
                    }
                }
            }
        }
        input {
            padding: 9px 9px 10px 25px;
            border-radius: 25px;
            border: 1px solid;
            outline: none;
            color: rgba(0,0,0,.7);
            font-weight: 400;
            border: 1px solid rgba(0,0,0,.1);
        }
        input:focus {
            border: 1px solid rgba(0,0,0,.4);
        }
        button {
            color: white;
            background: #dc7100;
            border: none;
            outline: none;
            border-radius: 25px;
            padding: 7px 25px;
            cursor: pointer;
            &:hover {
                opacity: 0.9;
            }
        }
        .error-messge {
            display: inline-block;
            width: 100%;
            padding-left: 15px;
            padding-top: 10px;
            font-weight: 400;
            color: red;
        }
    }
}