$mobile: "only screen and (max-width: 767px)";
.home-page {
  display: flex;
  justify-content: center;
  min-height: calc(100% - 15px);
  padding: 0px 0px 15px 200px;
  @media #{$mobile} {
    padding-left: 0px;
  }
  .sidenav {
    background-color: #636363;
    width: 200px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    @media #{$mobile} {
      width: 0;
      transition: width .2s;
      overflow: hidden;
    }
    .logo {
      justify-content: center;
      display: flex;
      padding-top: 20px;
      img {
        width: 150px;
      }
    }
  }
  .opened-menu {
    @media #{$mobile} {
      width: 200px;
      transition: width .2s;
    }
  }
  .sidenav-overlay {
    display: none;
    @media #{$mobile} {
      display: block;
      width: calc(100% - 200px);
      background: rgba(0,0,0,.1);
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 2;
      transition: width .2s;
    }
  }
  .header {
    display: none;
    @media #{$mobile} {
      display: flex;
    }
    position: fixed;
    left: 0;
    right: 0;
    background: rgb(99,99,99);
    top: 0;
    z-index: 2;
    align-items: center;
    padding: 10px;
    .menu-icon {
      margin-right: 10px;
    }
    .header-logo {
      width: 150px;
    }
  }
  .profile {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
      width: 75px;
      border-radius: 50px;
    }
    p {
      font-size: 16px;
      color: #fff;
      text-align: center;
      font-weight: 900;
      width: 100%;
    }
  }
  .menu {
    padding: 50px 10px 0px 25px;
    .menu-item {
      padding-bottom: 15px;
      color: #fff;
      font-weight: 100;
      span {
        display: flex;
        cursor: pointer;
      }
      img {
        margin-right: 10px;
      }
    }
    .menu-item.active {
      font-weight: 500;
      font-size: 17px;
    }
  }
}
.home-content {
  width: 100%;
  height: 100%;
  padding: 0px 25px 0px 25px;
  @media #{$mobile} {
    padding: 45px 15px 0px 15px;
  }
  .order-table {
    min-height: calc(100vh - 400px);
  }
  .no-orders {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 470px);
  }
  .order-item {
    font-weight: 100;
    font-size: 14px;
    display: flex;
    min-height: 50px;
    align-items: center;
    margin-bottom: 5px;
    border-bottom: 1px solid lightgray;
    .mob-content {
      display: none;
    }
    @media #{$mobile} {
      display: block;
      border: 1px solid lightgray;
      margin-bottom: 10px;
      padding: 9px 9px 2px 9px;
      border-radius: 5px;
      position: relative;
      .mob-content {
        display: inline-block;
        font-weight: 400;
      }
      .non-mob {
        display: none;
      }
      .status-badges {
        width: calc(100% - 13px);
        text-align: right;
      }
      .blue-badge {
        color: deepskyblue;
        border: 1px solid deepskyblue;
        border-radius: 3px;
        padding: 0px 5px;
        margin-right: 10px;
        display: inline-block;
      }
      .new-badge {
        color: #259b25;
        border: 1px solid #259b25;
      }
      .completed-badge {
        color: red;
        border: 1px solid red;
      }
    }
    div[class^="ord-col-"] {
      width: 13%;
      padding-left: 10px;
      @media #{$mobile} {
        width: 100%;
        padding-left: 0px;
        padding-bottom: 7px;
      }
    }
    .ord-col-7 {
      min-width: 200px;
    }
  }
  .title-item {
    background-color: lightgray;
    border-bottom: none;
    @media #{$mobile} {
      display: none;
    }
    img {
      vertical-align: middle;
      margin-left: 5px;
      cursor: pointer;
      width: 9px;
    }
  }
  .update-button {
    border: none;
    padding: 7px 15px;
    background: rgba(0,0,0,.7);
    border-radius: 3px;
    cursor: pointer;
    color: #fff;
    outline: none;
    &:hover {
      background: rgba(0,0,0,1);
    }
    img {
      vertical-align: middle;
      margin-right: 3px;
    }
  }
  .title-col {
    font-weight: 500;
  }
  .filter-row {
    display: flex;
    padding: 20px 0px 20px 0px;
    .select-container {
      width: 45%;
      max-width: 300px;
      margin-right: 20px;
      position: relative;
      font-weight: 100;
      p {
        position: absolute;
        top: -40px;
        font-weight: 400;
      }
      @media #{$mobile} {
        width: calc(50% - 10px);
        margin-right: 0px;
      }
    }
    @media #{$mobile} {
      .mob-left {
        margin-right: 20px;
      }
    }
    input {
      border: none;
      outline: none;
      width: 100%;
      padding: 0;
      margin: 0;
      height: 100%;
      padding-left: 5px;
      font-weight: 100;
    }
    .input-field-container {
      border: 1px solid #cccccc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      width: calc(45% - 20px);
      max-width: 280px;
    }
  }
  .filter-area {
    padding: 0px 0px 30px 0px;
  }
  .pagination {
    display: inline-flex;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px lightblue;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.8;
    margin: 0;
    padding-left: 0px;
    list-style-type: none;
    li {
        border-left: 1px solid lightblue;
    }
    li a{
        padding: 7px 12px;
        display: inline-block;
        text-decoration: none;
        color: #000000;
    }
    li.prev-item {
        border: none;
    }
    li.prev-item, li.next-item {
        a {
            padding: 7px 14px;
        }
    }
    li.active {
        background-color: lightblue;
        a {
            color: #000000;
        }
    }
  }
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  }
  .pagination-details {
    display: flex;
    align-items: center;
    font-weight: 100;
    font-size: 14px;
    @media #{$mobile} {
      span {
        display: none;
      }
    }
  }
  .pagination-counts {
    width: 75px;
    margin-right: 15px;
    @media #{$mobile} {
      margin-right: 0px;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    button {
      outline: none;
      border: none;
      border-radius: 3px;
      color: #fff;
      background: rgba(0,0,0,.7);
      cursor: pointer;
      margin-right: 10px;
      padding: 7px 15px;
      &:hover {
        background: rgba(0,0,0,1);
      }
    }
    .open-btn {
      background: rgba(0, 155, 0, .9);
      &:hover {
        background: rgba(0, 155, 0, 1);
      }
    }
    .checkout-btn {
      background: #dc7100;
      &:hover {
        background: #a05302;
      }
    }
    img {
      cursor: pointer;
      opacity: .7;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.mob-content {
  display: none;
  @media #{$mobile} {
    display: block;
  }
}
.pagination-count-data {
  text-align: center;
  font-size: 14px;
}
.mobile-sort {
  display: none;
  @media #{$mobile} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 10px;
    p {
      width: 100%;
      margin-top: 0;
      font-weight: 400;
      margin-bottom: 7px;
    }
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #cccccc;
      border-radius: 3px;
      padding: 3px 7px;
      margin-bottom: 10px;
      font-size: 14px;
      img {
        width: 9px;
        margin-left: 3px;
      }
    }
  }
}
